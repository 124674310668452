$(document).ready(function () {
    $('section.about-us-carts .cards .card:nth-child(even)').addClass('ev');
    // $('section.about-us-carts .cards .card:first-child').addClass('wow animate__animated animate__fadeInLeft');
    var cartAboutUs = $('section.about-us-carts .cards .card');
    if(cartAboutUs.hasClass('ev')) {
        $(this).find('.img').addClass('ui');
    }
    $('section.about-us-carts .list-container .list-item:first-child').addClass('wow animate__animated animate__fadeInLeft');
    $('section.about-us-carts .list-container .list-item:nth-child(even)').addClass('wow animate__animated animate__fadeInRight');
        
    
});