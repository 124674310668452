function headerScroll(){
    if($(window).scrollTop()){
        $('header.site-header').addClass('scroll');
    }else{
        $('header.site-header').removeClass('scroll');
    }
}

headerScroll();

$(window).scroll(headerScroll);
$('.menu-item-has-children').find('.sub-menu').hide();

$('.menu-item-has-children').click(function(){
    var elem = $(this),
        menu = elem.find('.sub-menu');

    menu.fadeToggle(200);

    $(document).click(function (e){
		if (!elem.is(e.target) && elem.has(e.target).length === 0) {
			menu.fadeOut(200);
		}
	});


})
$('.menu-item-has-children > a').click(function(e){
    e.preventDefault();
});
