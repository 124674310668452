$(document).ready(function () {
    $('.accordion-body').hide();
    $('.accordion-head').click(function () {
        if($(this).hasClass('active')) {
            $(this).removeClass("active").next(".accordion-body").slideUp(400);
            $(this).find('span').removeClass('opened');
        } else {
            $(this).next('.accordion-body').slideDown(400);
            $(this).removeClass('active');
            $(this).addClass("active").next(".accordion-body").slideDown(400);
            $(this).find('span').addClass('opened');
        }
        return false;
    })
});