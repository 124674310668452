var animations = {
    'animateNews' : function(){
        var timer = 0,
            slides = $('section.news-part .slider-news-part .item-post');

        slides.each(function(){
            var elem = $(this);

            setTimeout(() => {
                elem.addClass('animate');
            }, timer);

            timer += 200;

        });

        delete animations['animateNews'];
    },
    'animateNewsPage' : function(){
        var timer = 0,
            items = $('.archive-news-section .news-container .item-post');

        items.each(function(){
            var elem = $(this);

            setTimeout(() => {
                elem.addClass('animate');
            }, timer);

            timer += 200;

        });

        delete animations['animateNewsPage'];
    },
    'animateTax' : function(){
        var timer = 0,
            items = $('section.tax-items .tax-items-wrapper .tax-item');

        items.each(function(){
            var elem = $(this);

            setTimeout(() => {
                elem.addClass('animate');
            }, timer);

            timer += 200;

        });

        delete animations['animateTax'];
    }
    
}

function checkScroll(){
    var sections = $('.animate-block'),
        scrolled = $(window).scrollTop();

    sections.each(function(){
        var elem = $(this),
            func = elem.data('func'),
            height = $(window).height() / 2;
            offset = elem.offset().top - height;

        if(offset <= scrolled){
            elem.addClass('animate');
            elem.removeClass('animate-block');
            
            if(animations[func]){
                animations[func]();
            }

        }
    })

};

$(document).ready(function () {    
    checkScroll();
    
    $(window).on('resize', checkScroll);
    $(document).on('scroll', checkScroll);
});