$(document).ready(function () {
    $('.default-slider').slick({
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        prevArrow: $('.slick-arrow').remove(),
        nextArrow: $('.slick-arrow').remove()
    });

    $('.slider-news-part').slick({
        slidesToShow: 3,
        slidesToScroll: 3,
        prevArrow: $('.prev-arrow_news'),
        nextArrow: $('.next-arrow_news'),
        responsive: [
            {
                breakpoint: 991,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2
                }
            },
            {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
            }
        ]
    });

    $('.default-slider-multy').slick({
        slidesToShow: 3,
        slidesToScroll: 3,
        prevArrow: '<button class="icon-left prev"></button>',
        nextArrow: '<button class="icon-right next"></button>',
        responsive: [
            {
                breakpoint: 768,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2
                }
            },
            {
                breakpoint: 576,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
            }

        ]
    });

    $('.slider-partners').slick({
        slidesToShow: 5,
        slidesToScroll: 5,
        prevArrow: '<button class="icon-left prev"></button>',
        nextArrow: '<button class="icon-right next"></button>',
        responsive: [
            {
                breakpoint: 768,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3
                }
            },
            {
                breakpoint: 576,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2
                }
            }
        ]

    });

    $('.documents-container').slick({
        slidesToShow: 3,
        slidesToScroll: 3,
        prevArrow: $('.prev-arrow_docs'),
        nextArrow: $('.next-arrow_docs'),
        responsive: [
            {
                breakpoint: 991,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2
                }
            },
            {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
            }
        ]
    });

    $('.products-wrapper').slick({
        slidesToShow: 3,
        slidesToScroll: 3,
        prevArrow: '<button class="icon-left prev"></button>',
        nextArrow: '<button class="icon-right next"></button>',
        responsive: [
            {
                breakpoint: 991,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2
                }
            },
            {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
            }
        ]
    });

    $('.product-images-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        asNavFor: '.product-images-nav',
        prevArrow: '<button class="icon-left prev"></button>',
        nextArrow: '<button class="icon-right next"></button>'
    });

    $('.product-images-nav').slick({
        slidesToShow: 5,
        slidesToScroll: 1,
        infinite: false,
        asNavFor: '.product-images-slider',
        focusOnSelect: true,
        prevArrow: false,
        nextArrow: false
    });
});